import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Header } from ".";
import logo from "@vf-alchemy/vattenfall-design-system/dist/img/vf-logo.svg";

// Styling
const Root = styled.div`
    position: sticky;
    top: 0;
    z-index: 9999;
    margin-top: 0;
    width: 100%;
    max-width: none;
    background-color: #fff;
    border-bottom: 1px solid #ddd;

    @media screen and (max-width: 1200px) {
        border: 0;
    }
    @media screen and (max-width: 1300px) {
        .vf-navigation__menu-list-item a {
            margin: 0 calc(0.7em - 0.2px);
        }
    }
`;

const Hamburger = styled.ul``;

const LogoWrapper = styled.div`
    width: 140px;
    position: absolute;
    left: 15px;
`;

const HeaderWrapper = styled.div`
    margin-bottom: 0;
    @media screen and (max-width: 1200px) {
        display: none;
    }
`;

const DefaultWrapper = styled.div`
    .vf-navigation__default {
        position: sticky;
    }
    .vf-topbar__logo {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        img {
            width: 140px;
        }
    }
    @media screen and (min-width: 1200px) {
        display: none;
    }
`;

const MobileNavLink = styled.a`
    font-size: "22px";
    padding: "15px 0";
`;
const DesktopNavLink = styled.a`
    font-size: 1rem !important;
    font-weight: 400 !important;
`;

// Markup
export const Navigation = () => {
    const [menu, setMenu] = useState(false);
    const [showLogo, setLogo] = useState(false);

    const changeHeader = () => {
        const shouldShow = window.scrollY > 90;
        if (shouldShow) {
            setLogo(true);
        } else {
            setLogo(false);
        }
    };

    useEffect(() => {
        if (window.scrollY > 90) {
            setLogo(true);
        } else {
            setLogo(false);
        }

        window.addEventListener("scroll", changeHeader);
        return () => {
            window.removeEventListener("scroll", changeHeader);
        };
    }, []);

    return (
        <>
            <HeaderWrapper>
                <Header />
            </HeaderWrapper>

            <Root>
                <nav className="vf-navigation__menu">
                    <DefaultWrapper>
                        <div className="vf-navigation__default">
                            <div className="vf-navigation__default-inner">
                                <span
                                    className="vf-icon-menu"
                                    id="vf-navigation-menu-icon"
                                    onClick={() => setMenu(!menu)}
                                ></span>

                                <a
                                    href="https://www.vattenfall.se/"
                                    className="vf-topbar__logo"
                                >
                                    <img src={logo} width="180" />
                                </a>
                            </div>
                        </div>
                    </DefaultWrapper>

                    <Hamburger
                        css={css`
                            display: ${menu ? "block" : "none"};
                        `}
                        className="vf-navigation__menu-list vf-navigation__menu-level-1"
                    >
                        <a
                            href="https://www.vattenfall.se/"
                            className="vf-navigation__logo-desktop"
                        >
                            <img src={logo} />
                        </a>

                        <li className="vf-navigation__menu-list-item">
                            <div>
                                <MobileNavLink
                                    href="https://www.vattenfall.se/elavtal/"
                                    id="vf-navigation__link"
                                >
                                    Elavtal
                                </MobileNavLink>
                            </div>
                        </li>

                        <li className="vf-navigation__menu-list-item">
                            <div>
                                <MobileNavLink
                                    href="https://www.vattenfall.se/solceller/"
                                    id="vf-navigation__link"
                                >
                                    Solceller
                                </MobileNavLink>
                            </div>
                        </li>

                        <li className="vf-navigation__menu-list-item">
                            <div>
                                <MobileNavLink
                                    href="https://www.vattenfall.se/varmepumpar/"
                                    id="vf-navigation__link"
                                >
                                    Värmepumpar
                                </MobileNavLink>
                            </div>
                        </li>

                        <li className="vf-navigation__menu-list-item">
                            <div>
                                <MobileNavLink
                                    href="https://www.vattenfall.se/fjarrvarme/"
                                    id="vf-navigation__link"
                                >
                                    Fjärrvärme
                                </MobileNavLink>
                            </div>
                        </li>

                        <li className="vf-navigation__menu-list-item">
                            <div>
                                <MobileNavLink
                                    href="https://www.vattenfall.se/for-hemmet/"
                                    id="vf-navigation__link"
                                >
                                    För hemmet
                                </MobileNavLink>
                            </div>
                        </li>
                        <li className="vf-navigation__menu-list-item">
                            <div>
                                <MobileNavLink
                                    href="https://www.vattenfall.se/ladda-elbilen/"
                                    id="vf-navigation__link"
                                >
                                    Ladda elbilen
                                </MobileNavLink>
                            </div>
                        </li>
                        <li className="vf-navigation__menu-list-item">
                            <div>
                                <MobileNavLink
                                    href="https://www.vattenfall.se/kundservice"
                                    id="vf-navigation__link"
                                >
                                    Kundservice
                                </MobileNavLink>
                            </div>
                        </li>
                        <li className="vf-navigation__menu-list-item">
                            <div>
                                <MobileNavLink
                                    href="https://www.vattenfall.se/mina-sidor-information/"
                                    id="vf-navigation__link"
                                >
                                    Mina sidor
                                </MobileNavLink>
                            </div>
                        </li>
                    </Hamburger>

                    <ul
                        className="vf-navigation__menu-list vf-navigation__menu-level-1"
                        css={css`
                            max-width: 1425px;
                            margin: auto;
                            /* margin-left: 80rem; */
                        `}
                    >
                        {showLogo && (
                            <LogoWrapper>
                                <a
                                    href="https://www.vattenfall.se/"
                                    className="vf-navigation__logo-desktop"
                                >
                                    <img src={logo} />
                                </a>
                            </LogoWrapper>
                        )}

                        <li className="vf-navigation__menu-list-item">
                            <div>
                                <DesktopNavLink
                                    href="https://www.vattenfall.se/elavtal/"
                                    id="vf-navigation__link"
                                >
                                    Elavtal
                                </DesktopNavLink>
                                <span className="vf-icon-more"></span>
                            </div>
                        </li>

                        <li className="vf-navigation__menu-list-item">
                            <div>
                                <DesktopNavLink
                                    href="https://www.vattenfall.se/solceller/"
                                    id="vf-navigation__link"
                                >
                                    Solceller
                                </DesktopNavLink>
                                <span className="vf-icon-more"></span>
                            </div>
                        </li>

                        <li className="vf-navigation__menu-list-item">
                            <div>
                                <DesktopNavLink
                                    href="https://www.vattenfall.se/varmepumpar/"
                                    id="vf-navigation__link"
                                >
                                    Värmepumpar
                                </DesktopNavLink>
                                <span className="vf-icon-more"></span>
                            </div>
                        </li>

                        <li className="vf-navigation__menu-list-item">
                            <div>
                                <DesktopNavLink
                                    href="https://www.vattenfall.se/fjarrvarme/"
                                    id="vf-navigation__link"
                                >
                                    Fjärrvärme
                                </DesktopNavLink>
                            </div>
                        </li>

                        <li className="vf-navigation__menu-list-item">
                            <div>
                                <DesktopNavLink
                                    href="https://www.vattenfall.se/for-hemmet/"
                                    id="vf-navigation__link"
                                >
                                    För hemmet
                                </DesktopNavLink>
                            </div>
                        </li>
                        <li className="vf-navigation__menu-list-item">
                            <div>
                                <DesktopNavLink
                                    href="https://www.vattenfall.se/ladda-elbilen/"
                                    id="vf-navigation__link"
                                >
                                    Ladda elbilen
                                </DesktopNavLink>
                            </div>
                        </li>
                        <li className="vf-navigation__menu-list-item">
                            <div>
                                <DesktopNavLink
                                    href="https://www.vattenfall.se/kundservice"
                                    id="vf-navigation__link"
                                >
                                    Kundservice
                                </DesktopNavLink>
                            </div>
                        </li>
                        <li className="vf-navigation__menu-list-item">
                            <div>
                                <DesktopNavLink
                                    href="https://www.vattenfall.se/mina-sidor-information/"
                                    id="vf-navigation__link"
                                >
                                    Mina sidor
                                </DesktopNavLink>
                            </div>
                        </li>
                    </ul>
                </nav>
            </Root>
        </>
    );
};
